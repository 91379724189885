import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { Container } from '../components/Container'
import { cx } from '../utils/cx'
import { StaticImage } from 'gatsby-plugin-image'

const useStyles = makeStyles(() => ({
  withStrong: {
    '& strong': {
      fontWeight: 700
    }
  },
  small: {
    fontSize: '1.5rem'
  },
  fullPage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

export function ToucanPage ({ children, small }) {
  const classes = useStyles()

  return (
    <Container withToolbar withBottomPadding className={classes.fullPage}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        flex={1}
      >
        <StaticImage src='../assets/tuca.png' height={150} alt='' />
        <Typography
          variant='h4'
          classes={{ root: cx(classes.withStrong, small && classes.small) }}
        >
          {children}
        </Typography>
      </Box>
    </Container>
  )
}
