import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ComingSoonPage } from '../components/ComingSoonPage'
import { Container } from '../components/Container'
import { Loader } from '../components/Loader'
import { Page } from '../components/Page'
import { useMobile } from '../utils/useMobile'
import {
  DesktopPublications,
  MobilePublications
} from '../components/PublicationsSection'
import { useCoreData } from '../utils/useCoreData'

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: 600,
    marginTop: '30px',
    marginBottom: '30px'
  }
}))

export default function Publications () {
  return (
    <Page>
      <PublicationsContent />
    </Page>
  )
}

function PublicationsContent () {
  const {
    api: {
      data: { miscellaneous, publications }
    }
  } = useCoreData()
  const mobile = useMobile()
  const classes = useStyles()
  if (!publications && !miscellaneous) return <Loader />
  if (!publications?.length) return <ComingSoonPage />

  return (
    <>
      <Container withToolbar>
        <Typography
          variant='h4'
          classes={{ root: classes.mainTitle }}
          paragraph
        >
          {miscellaneous.publications_title}
        </Typography>
      </Container>
      <Container unwrap={mobile} withBottomPadding>
        {mobile ? (
          <MobilePublications
            publications={publications}
            misc={miscellaneous}
          />
        ) : (
          <DesktopPublications
            publications={publications}
            misc={miscellaneous}
          />
        )}
      </Container>
    </>
  )
}
