import React from 'react'
import { useCoreData } from '../utils/useCoreData'
import { Loader } from './Loader'
import { ToucanPage } from './ToucanPage'

export function ComingSoonPage () {
  const {
    api: {
      data: { miscellaneous }
    }
  } = useCoreData()

  if (!miscellaneous) return <Loader />

  const { coming_soon } = miscellaneous
  const [first, second] = coming_soon.split(' ')
  return (
    <ToucanPage>
      {first} <strong>{second}</strong>
    </ToucanPage>
  )
}
