import React from 'react'
import { Box, Link, Typography } from '@material-ui/core'
import { Headline } from '../components/Headline'
import { Container } from './Container'
import { richText } from '../utils/richText'

export function PublicationCard ({ data, misc, mobile }) {
  return (
    <Box display='flex' flexDirection='column' mb='20px'>
      <Box mb='20px'>
        <img src={data.image.url} alt='' width='100%' />
      </Box>
      <Container unwrap={!mobile}>
        <Box lineHeight={0.5}>
          <Headline variant='h5' bold>
            {data.title},
          </Headline>
          <Headline variant='h5' paragraph>
            {data.author}
          </Headline>
          <Typography variant='body2' color='textSecondary' paragraph>
            {richText(data.description)}
          </Typography>
        </Box>
        <Link
          href={data.download_url.url}
          underline='always'
          download={data.title}
          target='_blank'
        >
          {misc.download_text}
        </Link>
      </Container>
    </Box>
  )
}
