import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { colors, respSectionPadding } from '../utils/theme'
import { PublicationCard } from './PublicationCard'
import { Slider } from './Slider'
import { useCoreData } from '../utils/useCoreData'
import { useMobile } from '../utils/useMobile'
import { Container } from './Container'
import { FullPage } from './FullPage'

const useStyles = makeStyles(() => ({
  withStrong: {
    '& strong': {
      fontWeight: 600,
      display: 'block'
    }
  },
  arrow: {
    color: colors.white,
    top: '35% !important'
  }
}))

// used in home
export function PublicationsSection () {
  const {
    api: {
      data: { publications: pubResults, miscellaneous }
    }
  } = useCoreData()
  const classes = useStyles()
  const mobile = useMobile()

  if (!pubResults) return null

  const title = miscellaneous.publications_title || ''

  const publications = (pubResults || [])
    .sort((a, b) => {
      const dateA = new Date(a.first_publication_date)
      const dateB = new Date(b.first_publication_date)
      return dateA < dateB ? 1 : -1
    })
    .slice(0, 3)

  return (
    <FullPage bgcolor={colors.lightGrey}>
      <Box py='40px'>
        <Container>
          <Box
            fontSize='3em'
            flexBasis='20%'
            lineHeight={1}
            mb='30px'
            className={classes.withStrong}
          >
            <strong>{title}</strong>
          </Box>
        </Container>
        <Container unwrap={mobile}>
          <div>
            {mobile ? (
              <MobilePublications
                publications={publications}
                misc={miscellaneous}
              />
            ) : (
              <DesktopPublications
                publications={publications}
                misc={miscellaneous}
              />
            )}
          </div>
        </Container>
      </Box>
    </FullPage>
  )
}

export function MobilePublications ({ publications, misc }) {
  const classes = useStyles()
  return (
    <Box mb={respSectionPadding(true) + 'px'}>
      <Slider interactable arrowClassname={classes.arrow}>
        {publications.map(({ data }) => (
          <PublicationCard data={data} misc={misc} mobile />
        ))}
      </Slider>
    </Box>
  )
}

export function DesktopPublications ({ publications, misc }) {
  return (
    <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gridGap='20px'>
      {publications.map(({ data }) => (
        <PublicationCard data={data} misc={misc} />
      ))}
    </Box>
  )
}

export default PublicationsSection
